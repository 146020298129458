<template>
  <div class="container signup-step-2">
    <div class="row mt-5">
      <div class="col-md-12">
        <div class="container">
          <div class="welcome-text px-4">
            <h1>{{ $t('confirm-email') }}</h1>
          </div>
          <div class="px-4">
            <div class="steps">
              <span class="current">{{ $t('signup.step') }} 2</span>
              <span class="total">{{$t('signup.out-of') }} 3</span>
            </div>
            <div class="signin-form row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-lg-4 col-md-12">
                    <h3>{{ $t('signup.enter-sms-code') }}</h3>
                    <span>{{ $t('signup.sended-to') }} {{ teacher.email }}</span>
                    <div class="form-code-input">
                      <CodeInput
                          :field-height="82"
                          :fields="4"
                          v-on:change="onChange"
                          v-on:complete="onComplete"
                      />
                      <p class="text-danger" v-if="invalidCode">{{ $t('signup.incorrect-code') }}</p>
                      <p v-if="canResend"><a href="#" @click="resend">{{ $t('signup.resend-code') }}</a></p>
                      <p v-if="!canResend">
                        {{ $t('signup.resend-code-after') }} {{ resendSec }} {{ $t('signup.resend-code-seconds') }}
                      </p>
                    </div>
                    <router-link
                        type="primary"
                        style="margin-left: 0"
                        class="btn-next"
                        :to="{name: 'teacher-signup-first-step'}"
                        tag="el-button"
                    >
                      {{ $t('school.back-btn') }}
                    </router-link>
                    <el-button
                        style="color: white; background-color: #409EFF"
                        type="button"
                        class="btn-next"
                        @click="isFreeEmail"
                        :disabled="!canGoNext"
                    >
                      {{ $t('signup.next-btn') }}
                    </el-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="error-modal" hide-footer hide-header>
      <div id="error-wrapper">
        <div id="dialog">
          <button @click="$bvModal.hide('error-modal')" type="button" class="close" data-dismiss="modal"
                  aria-label="Close">
            <span aria-hidden="true"></span>
          </button>
          <h3>{{ $t('signup.error-modal') }}</h3>
          <span>{{ errorMessage }}</span>
        </div>
      </div>
    </b-modal>

  </div>
</template>
<script>
import Vue from 'vue'
import {mapActions} from 'vuex'
import CodeInput from "vue-verification-code-input"
import axios from "axios";

export default {
  name: 'TeacherSignupStep2',
  components: {
    CodeInput,
  },
  data() {
    return {
      errorMessage: '',
      sendingSms: true,
      invalidCode: false,
      canResend: true,
      emailVerified: false,
      resendSec: 60,
      teacherId: null,
      code: null,
      teacher: {
        iin: '',
        email: '',
      },
      canGoNext: false,
    }
  },
  beforeMount() {
    this.teacher = JSON.parse(localStorage.getItem('teacherSignUpData') || {});
  },
  mounted() {
  },
  methods: {
    countdown() {
      if (this.resendSec === 60) {
        setInterval(() => {
          this.resendSec--;
          if (this.resendSec === 0) {
            this.canResend = true;
          }
        }, 1000);
      }
    },
    resend() {
      this.canResend = false;
      this.resendSec = 60;
      this.sendEmail({
        email: this.teacher.email,
      });
    },
    isFreeEmail() {
      axios.post(API_ROOT + '/api/teacher/is-free-email', {email: this.teacher.email,})
          .then(res => {
            Vue.toastr({
              message: 'Error',
              description: this.$t('signup_error_email'),
              type: 'error'
            })
          })
          .catch(res => {
            this.openModal()
          })
    },

    openModal() {
      this.$v.$touch()
      if (this.$v.$invalid) return

      if (this.emailVerified) {
        this.$router.push({ name: 'teacher-signup-third-step' })
      }
    },
    onChange(v) {
      this.code = v;
    },
    onComplete(v) {
      this.countdown();

      this.invalidCode = false;
      this.sendingSms = true;

      this.$http.post(window.API_ROOT + '/api/teacher/verification-code/verify', {
        user_id: this.teacher.id,
        email: this.teacher.email,
        code: +v,
      })
          .then((res) => {
            console.log('res', res)
            if (res.status === 200) {
              this.canGoNext = true;
              this.$router.push({ name: 'teacher-signup-third-step' });
            }
            if (res.status !== 200) {
              this.invalidCode = true;
            }
            this.sendingSms = false;
          })
          .catch(() => {
            this.sendingSms = false;
          });
    },
    status(validation) {
      return {
        error: validation.$error,
        dirty: validation.$dirty
      }
    },
    ...mapActions({
      'sendEmail': 'sendEmail',
    })
  }
}
</script>

